import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';
import { useHotkeys } from 'stimulus-use/hotkeys';
import { useTransition } from 'stimulus-use';

export default class extends Controller {
  connect() {
    useClickOutside(this);
    useHotkeys(this, { escape: [this.close] });
    useTransition(this, {
      element: this.element,
      enterActive: 'transition duration-75',
      enterFrom: 'opacity-0',
      enterTo: 'opacity-100',
      leaveActive: 'transition duration-200 ease-in',
      leaveFrom: 'translate-x-0 opacity-100',
      leaveTo: 'translate-x-full opacity-0',
    });

    this.enter();
  }

  close() {
    this.leave().then(() => this.element.remove());
  }

  clickOutside() {
    this.close();
  }
}
