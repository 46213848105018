import React, { useEffect } from 'react';
import {
  ReactFlow,
  Background,
  Controls,
  useNodesState,
  useEdgesState,
  useReactFlow,
  ReactFlowProvider,
  useNodesInitialized,
} from '@xyflow/react';
import ELK from 'elkjs/lib/elk.bundled.js';
import MindMapNode from './MindMapNode';

const elk = new ELK();

const defaultNodeProps = {
  type: 'mindmap',
  position: { x: 0, y: 0 },
  deletable: false,
  style: { opacity: 0 },
};

const defaultEdgeProps = {
  deletable: false,
  selectable: false,
  style: { opacity: 0 },
};

const nodeTypes = {
  mindmap: MindMapNode,
};

const getLayoutedNodes = async (nodes, edges) => {
  const graph = {
    id: 'root',
    layoutOptions: {
      'elk.algorithm': 'layered',
      'elk.direction': 'DOWN',
      'elk.spacing.nodeNode': 80,
      'elk.layered.spacing.nodeNodeBetweenLayers': 100,
      'elk.spacing.componentComponent': 100,
      'elk.padding': '[top=50,left=50,bottom=50,right=50]',
      'elk.layered.nodePlacement.strategy': 'SIMPLE',
    },
    children: nodes.map((node) => ({
      id: node.id,
      targetPosition: 'top',
      sourcePosition: 'bottom',
      width: node.measured?.width ?? 100,
      height: node.measured?.height ?? 50,
      ...node,
    })),
    edges: edges.map((edge) => ({
      id: edge.id,
      sources: [edge.source],
      targets: [edge.target],
    })),
  };

  const layoutedGraph = await elk.layout(graph);

  return nodes.map((node) => {
    const layoutedNode = layoutedGraph.children?.find((n) => n.id === node.id);
    return { ...node, position: { x: layoutedNode?.x ?? 0, y: layoutedNode?.y ?? 0 } };
  });
};

function MindMapContent({ channel, initialNodes, initialEdges }) {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes.map((node) => ({ ...defaultNodeProps, ...node })));
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges.map((edge) => ({ ...defaultEdgeProps, ...edge })));
  const { fitView } = useReactFlow();
  const nodesInitialized = useNodesInitialized();

  // handle turbo stream updates
  useEffect(() => {
    const handleStreamRender = (event) => {
      const targetFrame = event.target.target;
      if (!targetFrame || !targetFrame.startsWith(channel)) return;

      // remove doesn't send data, so we need to get it from the targetFrame
      let data = event.target.templateContent.textContent;
      if (event.target.action === 'remove') data = `{ "id": "${targetFrame.split('_')[1]}" }`;

      // parse JSON
      try {
        data = JSON.parse(data);
      } catch (e) {
        console.error('Failed to parse stream data:', e);
        return;
      }

      // handle node updates
      if (targetFrame === `${channel}_nodes` || targetFrame.startsWith('node_')) {
        if (event.target.action === 'append') {
          setNodes((nodes) => [...nodes, { ...defaultNodeProps, ...data }]);
        } else if (event.target.action === 'replace') {
          setNodes((nodes) => nodes.map((node) => (node.id === data.id ? { ...defaultNodeProps, ...data } : node)));
        } else if (event.target.action === 'remove') {
          setNodes((nodes) => nodes.filter((node) => node.id !== data.id));
        }
      }

      // handle edge updates
      if (targetFrame === `${channel}_edges` || targetFrame.startsWith('edge_')) {
        if (event.target.action === 'append') {
          setEdges((edges) => [...edges, { ...defaultEdgeProps, ...data }]);
        } else if (event.target.action === 'replace') {
          setEdges((edges) => edges.map((edge) => (edge.id === data.id ? { ...defaultEdgeProps, ...data } : edge)));
        } else if (event.target.action === 'remove') {
          setEdges((edges) => edges.filter((edge) => edge.id !== data.id));
        }
      }
    };

    document.addEventListener('turbo:before-stream-render', handleStreamRender);
    return () => document.removeEventListener('turbo:before-stream-render', handleStreamRender);
  }, [channel]);

  // layout effect for initial render and updates
  useEffect(() => {
    (async () => {
      const layoutedNodes = await getLayoutedNodes(nodes, edges);
      setNodes(layoutedNodes);

      // only show nodes after they are measured
      if (!nodesInitialized) return;
      setTimeout(() => {
        fitView();
        setNodes((nodes) => nodes.map((node) => ({ ...node, style: { opacity: 1 } })));
        setEdges((edges) => edges.map((edge) => ({ ...edge, style: { opacity: 1 } })));
      }, 50);
    })();
  }, [nodesInitialized]);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      nodesConnectable={false}
      minZoom={0.1}
      maxZoom={5}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      fitView
      proOptions={{ hideAttribution: true }}
    >
      <Background />
      <Controls showInteractive={false} />
    </ReactFlow>
  );
}

export default function MindMap(props) {
  return (
    <ReactFlowProvider>
      <MindMapContent {...props} />
    </ReactFlowProvider>
  );
}
