import { Controller } from '@hotwired/stimulus';
import { useResize } from 'stimulus-use';
import { createRoot } from 'react-dom/client';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, OrthographicCamera } from '@react-three/drei';
import { useState, useEffect } from 'react';

const MIN_ZOOM = 20;
const MAX_ZOOM = 80;

const Box = ({ position = [0, 0, 0], size = [4, 1, 1] }) => (
  <mesh position={position}>
    <boxGeometry args={size} />
    <meshBasicMaterial attach="material-0" color={'#000000'} />
    <meshBasicMaterial attach="material-1" color={'#000000'} />
    <meshBasicMaterial attach="material-2" color={'#000000'} />
    <meshBasicMaterial attach="material-3" color={'#000000'} />
    <meshBasicMaterial attach="material-4" color={'#FFFFFF'} />
    <meshBasicMaterial attach="material-5" color={'#FFFFFF'} />
  </mesh>
);

const Scene = ({ initialZoom, onZoomChange }) => {
  const [zoom, setZoom] = useState(initialZoom);
  useEffect(() => onZoomChange(setZoom), [onZoomChange]);

  return (
    <Canvas style={{ mixBlendMode: 'screen' }} flat linear>
      <color attach="background" args={['#FFFFFF']} />

      <OrthographicCamera makeDefault position={[-7, 5, 10]} zoom={zoom} />
      <OrbitControls
        target={[0, 0, 0]}
        minZoom={MIN_ZOOM}
        maxZoom={MAX_ZOOM}
        enablePan={false}
        enableDamping={true}
        dampingFactor={0.025}
        autoRotate={true}
        autoRotateSpeed={0.25}
      />

      <group position={[0, 0, 0]}>
        <group position={[-2, -2, 0]}>
          <Box size={[8, 1, 1]} />
          <Box size={[4, 1, 1]} position={[6, 1, 0]} />
          <Box size={[4, 1, 1]} position={[2, 2, 0]} />
          <Box size={[4, 1, 1]} position={[-2, 3, 0]} />
          <Box size={[8, 1, 1]} position={[4, 4, 0]} />
        </group>
      </group>
    </Canvas>
  );
};

export default class extends Controller {
  static targets = ['background', 'logo'];

  zoom = MAX_ZOOM;
  updateZoom = null;

  connect() {
    useResize(this);

    this.backgroundTarget.style.opacity = 0;
    setTimeout(() => (this.backgroundTarget.style.opacity = 1), 100);

    this.resize(this.element.getBoundingClientRect());
    createRoot(this.logoTarget).render(<Scene initialZoom={this.zoom} onZoomChange={(setZoom) => (this.updateZoom = setZoom)} />);
  }

  resize({ width, height }) {
    this.zoom = Math.max(MAX_ZOOM * Math.min(Math.min(width, height) / 1000, 1), MIN_ZOOM);
    if (this.updateZoom) this.updateZoom(this.zoom);
  }

  disconnect() {
    this.backgroundTarget.style.opacity = 0;
    this.logoTarget.innerHTML = '';
  }
}
