import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="toggler"
export default class extends Controller {
  static targets = ['switch', 'content'];

  static classes = ['switchOn', 'switchOff', 'contentOn', 'contentOff'];

  static values = {
    switchInitialState: { type: Boolean, default: false },
    contentInitialState: { type: Boolean, default: false },
  };

  connect() {
    if (this.hasSwitchTarget) {
      if (this.switchInitialStateValue) {
        if (this.hasSwitchOnClass) this.switchTarget.classList.add(this.switchOnClass);
        if (this.hasSwitchOffClass) this.switchTarget.classList.remove(this.switchOffClass);
      } else {
        if (this.hasSwitchOffClass) this.switchTarget.classList.add(this.switchOffClass);
        if (this.hasSwitchOnClass) this.switchTarget.classList.remove(this.switchOnClass);
      }
    }

    if (this.hasContentTarget) {
      if (this.contentInitialStateValue) {
        if (this.hasContentOnClass) this.contentTarget.classList.add(this.contentOnClass);
        if (this.hasContentOffClass) this.contentTarget.classList.remove(this.contentOffClass);
      } else {
        if (this.hasContentOnClass) this.contentTarget.classList.remove(this.contentOnClass);
        if (this.hasContentOffClass) this.contentTarget.classList.add(this.contentOffClass);
      }
    }
  }

  toggleSwitch() {
    if (!this.hasSwitchTarget) return;

    if (this.hasSwitchOnClass) this.switchTarget.classList.toggle(this.switchOnClass);
    if (this.hasSwitchOffClass) this.switchTarget.classList.toggle(this.switchOffClass);
  }

  toggleContent() {
    if (!this.hasContentTarget) return;

    if (this.hasContentOnClass) this.contentTarget.classList.toggle(this.contentOnClass);
    if (this.hasContentOffClass) this.contentTarget.classList.toggle(this.contentOffClass);
  }

  handleClickOutside(event) {
    // Don't do anything if the content is already hidden
    if (!this.contentTarget.classList.contains(this.contentOnClass)) return;

    // Check if the click was inside the content
    if (this.contentTarget.contains(event.target)) return;

    // Prevent clicks on any elements when dropdown is open
    event.preventDefault();
    event.stopPropagation();

    // Check if the click was outside both the switch and content
    if (!this.switchTarget.contains(event.target) && !this.contentTarget.contains(event.target)) {
      this.toggleContent();
      this.toggleSwitch();
    }
  }
}
