import { Controller } from '@hotwired/stimulus';
import consumer from '../channels/consumer';

export default class extends Controller {
  static values = {
    projectId: String,
    actorId: String,
  };

  connect() {
    this.channel = consumer.subscriptions.create(
      { channel: 'ActorStatusChannel', project_id: this.projectIdValue },
      { received: this.handleStatus.bind(this) },
    );
  }

  disconnect() {
    this.channel?.unsubscribe();
  }

  handleStatus({ actor_id, is_running }) {
    if (actor_id === this.actorIdValue) this.element.classList.toggle('hidden', !is_running);
  }
}
