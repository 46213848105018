import { Controller } from '@hotwired/stimulus';
import consumer from '../channels/consumer';
import { useWindowResize } from 'stimulus-use';

export default class extends Controller {
  static values = {
    projectId: String,
    actorId: String,
    padding: { type: Number, default: 0 },
  };

  static targets = ['iframe', 'url', 'cursor', 'canvas'];

  connect() {
    useWindowResize(this);

    if (this.hasProjectIdValue && this.hasActorIdValue) {
      this.handler = this.handleMessage.bind(this);
      window.addEventListener('message', this.handler);

      this.channel = consumer.subscriptions.create(
        { channel: 'ActorStatusChannel', project_id: this.projectIdValue },
        { received: this.handleStatus.bind(this) },
      );
    }

    this.windowResize();
  }

  disconnect() {
    window.removeEventListener('message', this.handler);
    this.channel?.unsubscribe();
  }

  windowResize() {
    this.element.style.display = 'none';
    const rect = this.element.parentElement.getBoundingClientRect();
    this.ratio = Math.max(0.1, Math.min(1.0, (rect.width - this.paddingValue * 2) / 1280));
    this.element.style.display = null;

    if (this.hasIframeTarget) this.iframeTarget.style.transform = `scale(${this.ratio})`;
    if (this.hasCanvasTarget) this.canvasTarget.style.transform = `scale(${this.ratio})`;
  }

  handleMessage(event) {
    if (event.data === 'browserbase-disconnected') window.location.reload();
  }

  handleStatus({ actor_id, current_url, current_cursor_position }) {
    if (actor_id !== this.actorIdValue) return;

    // reload to handle coming online while we're watching
    if (!this.hasIframeTarget) {
      window.location.reload();
      return;
    }

    if (this.hasUrlTarget) {
      this.urlTarget.innerText = current_url;
    }

    if (this.hasCursorTarget) {
      const [left, top] = current_cursor_position.split(',').map(Number);
      this.cursorTarget.style.left = `${left}px`;
      this.cursorTarget.style.top = `${top}px`;
    }
  }
}
