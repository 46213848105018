import { Controller } from '@hotwired/stimulus';
import React from 'react';
import { createRoot } from 'react-dom/client';

import MindMap from '../components/MindMap';

import '@xyflow/react/dist/style.css';

export default class extends Controller {
  static targets = ['container'];

  static values = {
    channel: String,
    nodes: Array,
    edges: Array,
  };

  connect() {
    const container = this.containerTarget;
    container.style.width = '100%';
    container.style.height = '100%';

    createRoot(container).render(<MindMap channel={this.channelValue} initialNodes={this.nodesValue} initialEdges={this.edgesValue} />);
  }
}
