import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'submit', 'container'];

  connect() {
    this.form = this.element;
  }

  beforeSubmit() {
    // disable form elements during submission
    this.inputTarget.disabled = true;
    this.submitTarget.disabled = true;
  }

  afterSubmit(event) {
    // re-enable form elements after submission
    this.inputTarget.disabled = false;
    this.submitTarget.disabled = false;

    // clear the input if submission was successful
    if (event.detail.success) {
      this.inputTarget.value = '';
    } else {
      alert('Failed to send message');
    }

    // refocus the input
    this.inputTarget.focus();

    // scroll message container to bottom
    if (this.hasContainerTarget) {
      this.containerTarget.scrollTop = this.containerTarget.scrollHeight;
      setTimeout(() => (this.containerTarget.scrollTop = this.containerTarget.scrollHeight), 100);
    }
  }
}
